.text-center {
  text-align: center; }

.full-width {
  width: 100%; }

.error {
  color: #FF2A77; }

.f-right {
  float: right; }

hr {
  color: #D7D7D7;
  border-color: #D7D7D7;
  border-style: solid;
  border-width: 0.5px;
  margin: 30px 0; }

.bold {
  font-weight: bold; }

.page-text {
  text-align: center;
  width: 300px;
  color: #4f4f4f;
  margin: auto;
  max-width: 100%;
  margin-bottom: 30px;
  opacity: 0.88; }

@media screen and (max-width: 480px) {
  .page-text {
    padding: 0 20px;
    margin-bottom: 20px; }
  .ForgotForm {
    padding: 0 20px; } }

h1 {
  font-size: 24px;
  font-weight: bold;
  color: #0e0e0e;
  margin-bottom: 32px; }

h5 {
  color: #0e0e0e; }

.error-text {
  margin-bottom: 0; }

.ant-form-item {
  margin-bottom: 16px;
  font-variant: none;
  font-feature-settings: "kern" 0;
  -webkit-font-feature-settings: normal !important;
  -moz-font-feature-settings: normal !important; }

.ant-input {
  font-family: 'Ubuntu', sans-serif;
  padding: 6px 20px;
  font-size: 16px;
  height: auto;
  max-width: 100%;
  border-radius: 5px; }
  .ant-input.has-error {
    border-color: #FF2A77; }
    .ant-input.has-error:hover {
      border-color: #FF2A77; }
    .ant-input.has-error:focus {
      box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2); }
  .ant-input:hover {
    border-color: #2b5adc; }
  .ant-input:focus {
    border-color: #2b5adc;
    box-shadow: 0 0 0 2px rgba(30, 145, 253, 0.2);
    color: #2b5adc; }
  .ant-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #FFFFFF inset; }

.ant-form {
  margin-bottom: 50px;
  text-align: center;
  font-family: 'Ubuntu', sans-serif; }
  .ant-form .ant-col-12 {
    width: calc(50% - 8px); }
    .ant-form .ant-col-12:last-child {
      float: right; }
  .ant-form .form-inline input {
    width: 168px; }
  .ant-form .ant-form-item {
    font-family: 'Ubuntu', sans-serif;
    margin-bottom: 20px; }
    .ant-form .ant-form-item .has-success .ant-input {
      border-color: #2b5adc; }
    .ant-form .ant-form-item .has-success .ant-form-item-children-icon {
      display: none; }
    .ant-form .ant-form-item .has-error .ant-input {
      border-color: #fc3824;
      background-color: rgba(252, 56, 36, 0.11);
      color: #000; }
      .ant-form .ant-form-item .has-error .ant-input:focus {
        box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2); }
    .ant-form .ant-form-item .has-error .ant-form-explain {
      text-align: left;
      padding-left: 20px;
      color: #fc3824;
      font-size: 12px;
      line-height: 14px;
      min-height: 14px;
      margin-top: 5px; }

.input-error {
  display: block;
  color: #FF2A77;
  font-size: 10px;
  line-height: 10px;
  overflow: overlay;
  animation-name: fadeIn;
  animation-duration: 750ms;
  -webkit-animation-name: fadeIn; }

@keyframes fadeIn {
  from {
    opacity: 0;
    height: 0; }
  to {
    opacity: 1;
    height: 10px; } }

@media screen and (max-width: 480px) {
  .ant-input {
    width: 100%; } }

.ant-btn {
  font-size: 16px;
  height: auto;
  padding: 0;
  box-shadow: none;
  max-width: 100%;
  border: none; }
  .ant-btn:hover {
    color: #2b5adc; }
  .ant-btn.ant-btn-sm {
    font-size: 14px; }
  .ant-btn:disabled {
    opacity: 0.44;
    color: #fff;
    background-color: #2b5adc; }
    .ant-btn:disabled:hover {
      color: #fff;
      background-color: #2b5adc; }
  .ant-btn.ant-btn-primary {
    width: 285px;
    font-weight: 500;
    background-color: #2b5adc;
    border-color: #2b5adc;
    padding: 7px 15px;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1); }
    .ant-btn.ant-btn-primary.success {
      background-color: #00AADD; }
    .ant-btn.ant-btn-primary:hover {
      color: #FFFFFF;
      box-shadow: none; }
  .ant-btn.ant-btn-danger {
    width: 336px;
    font-weight: 500;
    background-color: #FF2A77;
    border-color: #FF2A77;
    color: #FFFFFF;
    border-radius: 10px;
    padding: 7px 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1); }
    .ant-btn.ant-btn-danger:hover {
      color: #FFFFFF;
      box-shadow: none; }
  .ant-btn.facebook {
    width: 336px;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #4967AD;
    border-color: #4967AD;
    border-radius: 10px;
    padding: 7px 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1); }
    .ant-btn.facebook:hover {
      color: #FFFFFF;
      box-shadow: none; }

#root {
  height: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-variant: none;
  font-feature-settings: "kern" 0;
  -webkit-font-feature-settings: normal !important;
  -moz-font-feature-settings: normal !important; }
  #root .auth-layout {
    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: #fff; }
    #root .auth-layout .auth-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      #root .auth-layout .auth-content .page-content {
        width: 330px;
        max-width: 96%;
        margin: auto; }
      #root .auth-layout .auth-content .PasswordInput {
        position: relative; }
        #root .auth-layout .auth-content .PasswordInput a {
          position: absolute;
          right: 20px;
          text-decoration: underline;
          top: 10px;
          font-size: 12px;
          color: #0e0e0e; }
      #root .auth-layout .auth-content .BottomLink {
        text-align: center;
        font-size: 16px; }
        #root .auth-layout .auth-content .BottomLink a {
          font-weight: bold; }
      #root .auth-layout .auth-content h1 {
        font-size: 36px;
        font-weight: 500;
        color: #0e0e0e;
        margin-bottom: 40px; }
        #root .auth-layout .auth-content h1.with-desc {
          margin-bottom: 10px; }
        #root .auth-layout .auth-content h1 span {
          position: relative;
          z-index: 1; }
          #root .auth-layout .auth-content h1 span::after {
            content: '';
            display: block;
            height: 4px;
            background-color: #ffdb13;
            position: absolute;
            bottom: 8px;
            z-index: -1;
            width: 100%;
            left: 0; }
        #root .auth-layout .auth-content h1.small {
          font-size: 28px; }
          #root .auth-layout .auth-content h1.small span::after {
            bottom: 6px; }
      #root .auth-layout .auth-content .auth-logo {
        text-align: center;
        max-width: 100%;
        height: 70px;
        margin-bottom: 20px; }
        #root .auth-layout .auth-content .auth-logo img {
          height: 100%;
          cursor: pointer; }

a {
  color: #2b5adc; }

header {
  display: none;
  border-bottom: 1px solid rgba(25, 25, 25, 0.11);
  text-align: center;
  padding: 7px; }
  header img {
    width: 90px; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media screen and (min-width: 480px) and (max-width: 1024px) {
  #root .auth-layout .auth-content h1 {
    font-size: 28px; }
    #root .auth-layout .auth-content h1 span::after {
      bottom: 5px; } }

@media screen and (max-width: 480px) {
  html {
    height: auto;
    background-color: #2b5adc; }
  header {
    display: block; }
  body {
    height: auto; }
  #root {
    height: auto; }
    #root .auth-layout {
      height: auto;
      flex-direction: column-reverse; }
      #root .auth-layout .Info {
        max-width: 100%;
        padding: 20px; }
        #root .auth-layout .Info img {
          display: none; }
        #root .auth-layout .Info p {
          font-size: 13px;
          margin-top: 10px;
          margin-bottom: 30px;
          opacity: 0.88; }
        #root .auth-layout .Info .bottom {
          font-size: 11px; }
        #root .auth-layout .Info h1 {
          font-size: 24px;
          width: 220px; }
          #root .auth-layout .Info h1::after {
            height: 4px;
            bottom: 6px;
            width: 94px; }
      #root .auth-layout .auth-content {
        padding: 40px 0; }
        #root .auth-layout .auth-content .page-content {
          width: auto; }
        #root .auth-layout .auth-content h1 {
          font-size: 18px;
          margin-bottom: 20px; }
          #root .auth-layout .auth-content h1.with-desc {
            margin-bottom: 10px; }
          #root .auth-layout .auth-content h1 span::after {
            height: 4px;
            bottom: 3px; }
        #root .auth-layout .auth-content .ant-btn-primary {
          height: 40px; } }

.Info {
  display: flex;
  flex-direction: column;
  background-color: #2b5adc;
  width: 600px;
  height: 100%;
  color: #fff;
  padding: 120px 110px; }
  .Info img {
    width: 150px; }
  .Info h1 {
    position: relative;
    z-index: 1;
    width: 390px;
    max-width: 100%;
    line-height: normal;
    margin-bottom: 10px;
    font-size: 52px;
    margin-bottom: 0;
    color: #fff;
    margin-top: auto; }
    .Info h1::after {
      content: '';
      display: block;
      height: 8px;
      background-color: #ffdb13;
      position: absolute;
      bottom: 10px;
      z-index: -1;
      width: 347px;
      left: 0; }
  .Info p {
    margin-top: auto;
    opacity: 0.88;
    font-size: 16px; }
  .Info a {
    color: #fff;
    text-decoration: underline; }
  .Info .bottom {
    font-size: 14px; }
  .Info .mt-auto {
    margin-top: auto; }
  @media screen and (min-width: 480px) and (max-width: 1024px) {
    .Info {
      width: 440px;
      padding: 70px 44px; }
      .Info img {
        width: 93px; }
      .Info h1 {
        font-size: 42px; }
        .Info h1::after {
          height: 4px;
          bottom: 8px;
          width: 281px; }
      .Info p {
        font-size: 14px; } }
  @media screen and (min-width: 480px) and (max-width: 925px) {
    .Info {
      width: 300px;
      padding: 70px 30px; }
      .Info h1 {
        font-size: 30px; }
        .Info h1::after {
          bottom: 6px;
          width: 200px; } }

body {
  color: #191919; }
